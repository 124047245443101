import validate from "/home/admin/www/pronouns.page/release/20250119174931/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_esggzfy5fxpj3hrvklsx4qwgpu/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/home/admin/www/pronouns.page/release/20250119174931/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/admin/www/pronouns.page/release/20250119174931/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@20.16.5_db0@0.2.1_encoding@_esggzfy5fxpj3hrvklsx4qwgpu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "layout-from-query": () => import("/home/admin/www/pronouns.page/release/20250119174931/middleware/layoutFromQuery.ts")
}