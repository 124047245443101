
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsQdqZLajVWnMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93OjjIqxYQAKMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editorlBQCSJvPDwMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/blog/editor.vue?macro=true";
import { default as indexhghpiv7Q8SMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/index.vue?macro=true";
import { default as moderationKc7Hsuz2NhMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/moderation.vue?macro=true";
import { default as pendingBansq1YtXWJY49Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/pendingBans.vue?macro=true";
import { default as profilesn4qGhGHBgDMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/profiles.vue?macro=true";
import { default as expenses4BXxJ4hmeQMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexiAYrQD2DjyMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewH1WyAIGjThMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingQ0Kbi0pwitMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingY5NExEOr2FMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/translations/missing.vue?macro=true";
import { default as usersET14l87xnXMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/admin/users.vue?macro=true";
import { default as apiYUOolZ8aSqMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/api.vue?macro=true";
import { default as _91slug_93yXlZphVqmhMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/blog/[slug].vue?macro=true";
import { default as indexI4QIhbNKXxMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93uyuM6RJdmLMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93y5AG4ZhtPUMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminGletPwK6WuMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/census/admin.vue?macro=true";
import { default as indexhYaXj7QY5oMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/census/index.vue?macro=true";
import { default as contactd9BsHSjw0OMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/contact.vue?macro=true";
import { default as designsUISaK6BEAMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/design.vue?macro=true";
import { default as english4QhOi9hqiwMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/english.vue?macro=true";
import { default as faqL0ORvBgGLLMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/faq.vue?macro=true";
import { default as inclusivejp6j3HGcvFMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/inclusive.vue?macro=true";
import { default as index_45homekaT1cBhtk0Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/index-home.vue?macro=true";
import { default as index_45localeoTkw2M2ZahMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/index-locale.vue?macro=true";
import { default as langswitchgnpbvIsiH0Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/langswitch.vue?macro=true";
import { default as licensegCfHdkJlg2Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/license.vue?macro=true";
import { default as academicNYknI5rruGMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/links/academic.vue?macro=true";
import { default as indexF3Lzgc6MokMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/links/index.vue?macro=true";
import { default as mediaESk5LgwccxMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/links/media.vue?macro=true";
import { default as translinguisticsaQ4oxvVv65Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/links/translinguistics.vue?macro=true";
import { default as zineGnDUQR7yrFMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/links/zine.vue?macro=true";
import { default as namesrQVKKHTiJXMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/names.vue?macro=true";
import { default as indexTC96Fz6J1VMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/nouns/index.vue?macro=true";
import { default as templatesYImn8S2Zu8Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/nouns/templates.vue?macro=true";
import { default as people4UysPzmkLdMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/people.vue?macro=true";
import { default as privacyPw0aQ6AJqyMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/privacy.vue?macro=true";
import { default as _91username_932ohYCJAAJ5Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93FyU1r4ZR06Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/profile/card-[username].vue?macro=true";
import { default as editoryBd5dfaWb6Meta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/profile/editor.vue?macro=true";
import { default as anydtRsKf91YAMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/any.vue?macro=true";
import { default as askRehUsf97hkMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/ask.vue?macro=true";
import { default as avoidingDDNEHKT6hFMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/avoiding.vue?macro=true";
import { default as indexgRnIrzWhaUMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/index.vue?macro=true";
import { default as mirrorlkPxRQEMGbMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun7Vtf6Ek8XeMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesRJ9EWBjSmkMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/sources.vue?macro=true";
import { default as teamqcUVweEVaaMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/team.vue?macro=true";
import { default as terminologyi4e3JM0nHjMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/terminology.vue?macro=true";
import { default as termsW8CfWeIwtyMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/terms.vue?macro=true";
import { default as user3xCheH5LXJMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/user.vue?macro=true";
import { default as workshopswtFLLfbm6zMeta } from "/home/admin/www/pronouns.page/release/20250119174931/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsQdqZLajVWnMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansq1YtXWJY49Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiYUOolZ8aSqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93yXlZphVqmhMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexI4QIhbNKXxMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93uyuM6RJdmLMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93y5AG4ZhtPUMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminGletPwK6WuMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexhYaXj7QY5oMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactd9BsHSjw0OMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english4QhOi9hqiwMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqL0ORvBgGLLMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivejp6j3HGcvFMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homekaT1cBhtk0Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localeoTkw2M2ZahMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchgnpbvIsiH0Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicNYknI5rruGMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexF3Lzgc6MokMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaESk5LgwccxMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsaQ4oxvVv65Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineGnDUQR7yrFMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesrQVKKHTiJXMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexTC96Fz6J1VMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesYImn8S2Zu8Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people4UysPzmkLdMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyPw0aQ6AJqyMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_932ohYCJAAJ5Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93FyU1r4ZR06Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editoryBd5dfaWb6Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anydtRsKf91YAMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askRehUsf97hkMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingDDNEHKT6hFMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexgRnIrzWhaUMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorlkPxRQEMGbMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun7Vtf6Ek8XeMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesRJ9EWBjSmkMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamqcUVweEVaaMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyi4e3JM0nHjMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsW8CfWeIwtyMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user3xCheH5LXJMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopswtFLLfbm6zMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20250119174931/pages/workshops.vue")
  }
]